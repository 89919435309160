import React from "react"
import { graphql } from "gatsby"
import SEO from "../utils/seo"
import styled from "styled-components"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Search from "../components/search/search"

const Wrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin: 0 auto;
`
const Content = styled.div`
  margin-bottom:2rem;
`
const PropertyPage = ({ data, pageContext }) => {

  const localName = pageContext.locationName
  const localDesc = pageContext.locationDescription
  const locs = data?.bookingGraph?.getPropertyLocIdByName
  const localLocId = locs ? locs[0]?.id.toString():null
  const localUri = pageContext.uri

  var locationArray = { where: { id: localLocId } }

  return (
    <>

      <SEO
        title={localName}
        description={localDesc}
        canonical={localUri}
        article={false}
      />

      <Breadcrumbs currentPage={localName} />

      <Wrapper>

        <h1 dangerouslySetInnerHTML={{ __html: localName }} />

        {localDesc &&
          <Content dangerouslySetInnerHTML={{ __html: localDesc }} />
        }

        <Search
          showResults={true}
          filters={locationArray}
          properties={[]}
        />

      </Wrapper>
    </>
  )

}

export default PropertyPage

export const pageQuery = graphql`
  query($locationName: String!, $locName: String) {
    allWpProperty(filter: {propertyLocations: {nodes: {elemMatch: {name: {eq: $locationName}}}}}) {
      nodes {
          title
          uri
          propertyLocations {
            nodes {
              name
            }
          }
      }
    }
    bookingGraph {
      getPropertyLocIdByName(name: $locName) {
        id
      }    
    }
  }
`
